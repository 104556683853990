import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const vuexLocal = createPersistedState({
  storage: window.localStorage,
  reducer: state => ({
    loc: state.loc
  })
})

export default new Vuex.Store({
  plugins: [vuexLocal],
  state: {
    page: {},
    loc: "",

    site_id: "chameleon",
    settings: null,
    main_pages: null,
    home_page: null,
    about_page: null,
    work_page: null,

    homeID: "ic_uXgrW8",
    workID: "iErvXbaWE",
    smallBusinessID: "T-U4-SKtR",
    whatWeDoID: "8Ly_1N47l",
    aboutID: "_PQuPnBBO",
    contactID: "3VUdP_VAh",
    privacyID: "USBUMCsZl",
    termsID: "lLhxMJfuh"
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isWorkPage(state) {
      return state.page.ItemID && state.page.ItemID == state.workID
    },
    isSmallBusinessPage(state) {
      return state.page.ItemID && state.page.ItemID == state.smallBusinessID
    },
    isWhatWeDoPage(state) {
      return state.page.ItemID && state.page.ItemID == state.whatWeDoID
    },
    isAboutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.aboutID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isPrivacyPage(state) {
      return state.page.ItemID && state.page.ItemID == state.privacyID
    },
    isTermsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.termsID
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setLoc(state, loc) {
      switch (loc) {
        case "d":
          state.loc = "d"
          break
        case "r":
          state.loc = "r"
          break
        default:
          state.loc = ""
      }

      state.loc = loc
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setAboutPage(state, page) {
      state.about_page = page
    },
    setWorkPage(state, page) {
      state.work_page = page
    },
    setHomePage(state, page) {
      state.home_page = page
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getHomePage({ commit, state }) {
      return new Promise(resolve => {
        if (!state.home_page)
          api.getPageByItemID(state.homeID).then(home => {
            commit("setHomePage", home)
            resolve()
          })
        else resolve()
      })
    },
    getAboutPage({ commit, state }) {
      return new Promise(resolve => {
        if (!state.about_page)
          api.getPageByItemID(state.aboutID).then(about => {
            commit("setAboutPage", about)
            resolve()
          })
        else resolve()
      })
    },
    getWorkPage({ commit, state }) {
      return new Promise(resolve => {
        if (!state.work_page)
          api.getPageByItemID(state.workID).then(work => {
            commit("setWorkPage", work)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.main_pages)
          api.getPages("", "page.main").then(main_pages => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach(page => {
              promises.push(api.getPages("", "page.sub=@" + page.ItemID).then(subs => Vue.set(page, "subs", subs)))
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})
