import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/work/wortley-brothers",
      name: "project-0",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Web Design Agency Mansfield, Mansfield web design ",
        description:
          "Chameleon Creative, a web design agency based near Mansfield. We deliver website design for businesses in Mansfield. Call 01777 553433 for a free quote.",
        footer: `<p><strong>More about Chameleon Creative...</strong> If you&rsquo;re looking for a well established, credible <strong>web design agency Mansfield</strong> and you have a <strong>website design Mansfield</strong> project to discuss, please get in touch with Chameleon Creative.</p>
        <p>We are a <strong>digital marketing agency Mansfield</strong>, creating <strong>brand designs</strong>, <strong>websites</strong> and <strong>marketing campaigns</strong> for local businesses in <strong>Mansfield</strong> and throughout <strong>North Nottinghamshire</strong>. Being based in <strong>Retford</strong> makes us the perfect choice when you&rsquo;re looking for a <strong>Mansfield marketing agency</strong>.</p>
        <p>We specialise in <strong>Web Design Mansfield</strong> and <strong>Digital Marketing Mansfield</strong>.</p>`
      }
    },
    {
      path: "/work/fullgasclub",
      name: "project-1",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "App Development Doncaster, Web Design Doncaster",
        description:
          "Chameleon Creative, We're a web & app development agency who design and build websites and apps. Est. 1995. A down to earth digital agency based in Doncaster.",
        footer: `<p><strong>More about Chameleon Creative...</strong> We&apos;re an <strong>App development agency</strong> based in <strong>Doncaster</strong> who create and deliver effective <strong>mobile apps</strong> and <strong>web design</strong> throughout <strong>Doncaster</strong>.</p>
        <p>Being a local <strong>Doncaster digital marketing agency</strong>, it enables us to service clients throughout the <strong>South Yorkshire</strong> region with ease and efficiency.</p>
        <p>The past 26 years has taught us well, <strong>design and print</strong> has migrated more to <strong>mobile apps</strong>, <strong>digital marketing</strong>, <strong>web design</strong> and <strong>email marketing</strong>. We have retained the traditional marketing skills and advanced to become a highly credible and trusted <strong>web design</strong> and <strong>digital marketing</strong> specialist in Doncaster and also throughout the U.K.</p>`
      }
    },
    {
      path: "/work/obsurvus",
      name: "project-2",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Web Design Agency Worksop, Website Design Worksop",
        description:
          "Chameleon Creative, a branding and web design agency based near Worksop. We deliver website design for businesses in Worksop. Call 01777 553433 for a free quote.",
        footer: `<p><strong>More about Chameleon Creative...</strong> If you&rsquo;re looking for a well established, credible <strong>web design agency Worksop</strong> and you have a <strong>website design Worksop</strong> project to discuss, please get in touch with Chameleon Creative.</p>
        <p>We are a <strong>digital marketing agency Worksop</strong>, creating <strong>brand designs</strong>, <strong>websites</strong> and <strong>marketing campaigns</strong> for local businesses in <strong>Worksop</strong> and throughout <strong>North Nottinghamshire</strong>. Being based in <strong>Retford</strong> makes us the perfect choice when you&rsquo;re looking for a <strong>Worksop marketing agency</strong>.</p>
        <p>We specialise in <strong>Web Design Worksop</strong> and <strong>Digital Marketing Worksop</strong>.</p>`
      }
    },
    {
      path: "/work/circuit-days",
      name: "project-3",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Marketing Agency Worksop, Digital Marketing Worksop",
        description:
          "Chameleon Creative, we are the Worksop digital design agency that delivers strong marketing, branding and logo design. Est. 1995, Call 01777 553433.",
        footer: `<p><strong>More about Chameleon Creative...</strong> We&apos;re a <strong>digital marketing agency Worksop</strong> who create affordable, effective <strong>marketing campaigns Worksop</strong>.</p>
        <p>Being a local <strong>Worksop digital marketing agency</strong>, it enables us to service clients throughout the <strong>North Nottinghamshire</strong> region with ease and efficiency.</p>
        <p>We specialise in <strong>digital marketing Worksop</strong> and <strong>marketing</strong> services for businesses in <strong>Worksop</strong>.</p>`
      }
    },
    {
      path: "/work/funeral-directors",
      name: "project-4",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Doncaster marketing agency, digital marketing agency Doncaster",
        description:
          "At Chameleon Creative we provide an honest and transparent service for all of our Funeral Director Clients",
        footer: `<p><strong>More about Chameleon Creative...</strong> If you&rsquo;re looking for a <strong>Doncaster Funeral Director marketing agency</strong>, <strong>Funeral Director</strong> <strong>web design agency Doncaster</strong> and you have a <strong>web design</strong> project to discuss, please get in touch.</p>
        <p>Being a local and affordable <strong>Funeral Director</strong> <strong>marketing agency Doncaster </strong>it enables us to service Funeral Directors throughout the <strong>South Yorkshire</strong> region with ease and efficiency.</p>
        <p>The past 27 years has taught us well, <strong>design and print </strong>for<strong> Funeral Directors</strong> has migrated more to <strong>digital marketing</strong>, <strong>web design Funeral Directors</strong> and <strong>email marketing</strong>.</p>
        <p>We have retained the traditional marketing skills and advanced to becoming a highly credible and trusted supplier for <strong>Funeral Directors web design</strong> and <strong>Funeral Directors design </strong>and<strong> marketing</strong> specialist in <strong>Doncaster</strong> and also throughout the U.K.</p>`
      }
    },
    {
      path: "/work/lincoln-welding",
      name: "project-5",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Ecommerce website Doncaster, e-commerce website design ",
        description:
          "Chameleon Creative, a web design agency that design and build e-commerce websites. Est. 1995. A down to earth website design company, Doncaster 01302 515700.",
        footer: `<p><strong>More about Chameleon Creative...</strong> We&apos;re an <strong>E-commerce web design agency Doncaster</strong> who deliver affordable <strong>ecommerce</strong> web solutions.</p>
        <p>Being a local <strong>digital marketing agency Doncaster</strong>, producing <strong>web design</strong> and <strong>e-commerce websites</strong> we are able to service clients throughout the <strong>South Yorkshire</strong> region with ease and efficiency.</p>
        <p>If you&rsquo;re looking for a highly credible and trusted <strong>Doncaster web design agency</strong> for a <strong>ecommerce web design</strong> project, please get in touch.</p>
        <p>We specialise in <strong>Web Design Doncaster</strong> and developing <strong>Ecommerce Web design Doncaster</strong>.</p>`
      }
    },
    {
      path: "/work/sypf",
      name: "project-6",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Doncaster digital marketing agency, web design agency Doncaster",
        description:
          "Chameleon Creative, a branding and web design agency situated in South Yorkshire. We deliver digital marketing services throughout South Yorks. Call 01302 515700 for a quote.",
        footer: `<p><strong>More about Chameleon Creative...</strong> If you&rsquo;re looking for a down to earth <strong>marketing agency Doncaster</strong>, <strong>web design agency Doncaster</strong> and you have a <strong>web design</strong> project to discuss, please get in touch.</p>
        <p>Being a local <strong>Doncaster digital marketing agency</strong>, it enables us to service clients throughout the <strong>South Yorkshire</strong> region with ease and efficiency.</p>
        <p>The past 26 years has taught us well, <strong>design and print</strong> has migrated more to <strong>online marketing</strong>, <strong>digital marketing</strong>, <strong>web design</strong> and <strong>email marketing</strong>. We have retained the traditional marketing skills and advanced to become a highly credible and trusted <strong>web design</strong> and <strong>digital marketing</strong> specialist in <strong>Doncaster</strong> and also throughout the U.K.</p>`
      }
    },
    {
      path: "/work/allsports",
      name: "project-7",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Branding Design Doncaster, Packaging Design Doncaster",
        description:
          "Chameleon Creative, a branding and packaging design agency situated in South Yorkshire. We deliver marketing services throughout South Yorks. Call 01302 515700 for a quote.",
        footer: `<p><strong>More about Chameleon Creative...</strong> If you&rsquo;re looking for a highly creative, passionate <strong>branding agency Doncaster</strong> and you have a <strong>logo design Doncaster</strong> project to discuss, please get in touch with Chameleon Creative.</p>
        <p>Being a local <strong>Doncaster marketing agency</strong>, it enables us to service clients throughout the <strong>South Yorkshire</strong> region with ease and efficiency.</p>
        <p>The past 26 years has taught us well, <strong>design and print</strong> has migrated more to <strong>online marketing</strong>, <strong>digital marketing</strong>, <strong>web design</strong> and <strong>email marketing</strong>. We have retained the traditional marketing skills and advanced to become a highly credible and trusted <strong>branding design</strong> and <strong>digital marketing</strong> specialist in <strong>Doncaster</strong> and also throughout the U.K.</p>`
      }
    },
    {
      path: "/web-design",
      name: "web-design",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Web Design by Chameleon Creative"
      }
    },
    {
      path: "/design-agency",
      name: "design-agency",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Chameleon Creative | Design Agency"
      }
    },
    {
      path:
        "/web-design-:location(retford|worksop|lincoln|mansfield|nottingham|derby|doncaster|rotherham|barnsley|sheffield|scunthorpe)",
      name: "web-design-landing",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue"),
      meta: {
        title: "Web Design by Chameleon Creative"
      }
    },

    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
