import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VueTypedJs from "vue-typed-js"
import inViewportDirective from "vue-in-viewport-directive"
import VueAnalytics from "vue-analytics"

import { library } from "@fortawesome/fontawesome-svg-core"
import { faBars, faTimes, faEnvelope, faPhone, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(faBars, faTimes, faFacebook, faInstagram, faTwitter, faLinkedinIn, faEnvelope, faPhone, faChevronDown)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === "production"
Vue.use(Meta)
Vue.use(VueAnalytics, {
  id: "UA-87398957-1",
  router,
  debug: {
    enabled: false,
    sendHitTask: isProd
  }
})
Vue.use(VueTypedJs)

inViewportDirective.defaults.top = -200
Vue.directive("in-viewport", inViewportDirective)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
